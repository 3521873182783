.invoice-actions{
    display: flex;
    justify-content: space-between;
}

.delete-button{
    color: red;
}

h2{
    text-align: center;
}

.single-invoice{
    margin: 2%;
}

.single-invoice-title{
    text-align: center;
    padding: 2% 5%;
}

.invoice-info{
    display: flex;
    justify-content: space-evenly;
    background: #dee2e6;
}

.basic-invoice-info{
    margin: 5px;
    width: 50%;
    padding: 20px;
    background: #e9ecef;

    b{
        color: #495057;
        font-weight: 400;
    }

    p{
        color:#6c757dd1;
    }
}

.invoice-content{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    .list-group-item-info {
        color: #212529;
        background-color: #e9ecef;
    }
}
.invoice-content-left{
    width: 59%;
}

.invoice-content-right{
   width: 39%;
}

.invoice-content-list{
    padding: 20px 5px 0;
}


.file-list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e9ecef;
}

.file-buttons{
    margin: 2px;
}

.add-note{
    float: right;
    margin-top: 10px;
}