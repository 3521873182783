.multiple-invoice{
    border: 1px solid #ced4d9;
    padding: 20px;
    color: #007bff;
}

.invoice-charge{
    display: flex;
}

.spacer{
    margin: 50px 0px;
}

.section-header{
    margin-bottom: 30px;
}

.total-updated{
    color: green;
}

.ad-purchase{
    border: #007bff solid 2px;
    padding: 20px;
    margin: 30px 0;
}
.multiple-by-selection{

    .custom-issues-selection{
        padding: 5px 50px;
    }

    .add-issue{
        margin: 5px 0 0 50px;
        background-color: #007bff;
    }

    .remove-issue{
        margin: 5px 0 0 5px;
        background-color: #d00115;
    }
}

.green-button{
    margin: 5px 0 0 5px;
    background-color: #007bff;
}

.red-button{
    margin: 5px 0 0 5px;
    background-color: #d00115;
}

.submit-screen{
    text-align: center;
}

.submit-button-container{
    height: 60px;
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: flex-end;
    text-align: center;
}

.success-title{
    text-align: center;
    padding: 50px;

    span{
        color: #007bff;
    }
}

.success-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;

    button{
        padding: 20px;
        margin: 20px;
        background-color: #0062cc;
    }
}