.sale-overlay-box {
    width:100%;
    padding: 5%;
    z-index: 15;
    position: absolute;
    backdrop-filter: blur(4px);
    background-color: #6f78805e;
}

.sale-overlay{
    padding: 1%;
    background-color:#f8f9fa;
    opacity: 0.98;
    width: 100%;
    z-index: 10;
    border: solid 2px #6f7880;
}

.close-btn{
    float: right;
    color: #6c757d;
    font-size: 24px;
    padding: 6px 15px 18px 15px;

    &:hover{
        color: #1d2124;
        text-decoration: none;
        cursor: pointer;
    }
}