.dashboard {
    display: flex;
    justify-content: space-evenly;

    @media screen and (max-width: 800px) {
        align-items: center;
        flex-direction: column-reverse;
    }
}

.quick-nav{
    margin: 100px 25px 0;
    display: flex;
    flex-direction: column;
    width: 400px;
    justify-content: space-between;

    button{
        font-size: 18px;
        margin: 6px;
    }
}

.updates-board{
    margin: 100px 25px;

    h6{
        text-align: center;
        margin-bottom: 50px;
    }
}