.form-button{
    display: flex;
    align-items: flex-end;
}

.reports-title{
    padding-top: 30px;
}

.reported-sale{
    display: flex;

    .added-on-date{
        color: #0062cc;
    }
}