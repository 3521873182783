.advertisers-page{

    @media screen and (max-width: 1000px){
        margin: 0 10vw;        
    }

    @media screen and (min-width: 1001px) and (max-width: 1600px){
        margin: 0 15vw;        
    }

    margin: 0 20vw;        

}

.advertisers-list{
    margin: 20px 0;
}