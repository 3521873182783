.ad-list{
    width: 40%;
    margin: 10px;
}

.ad-list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ad-date-filter-buttons{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}