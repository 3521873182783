.nav-link {
    cursor: pointer;
}

.breadcrumb-item {
    cursor: pointer;
}

.active.breadcrumb-item {
    cursor: default;
}

.form-modal{
    &.modal-dialog{
        max-width: 800px;
        margin: 5rem auto;
    }
}

.page-content-wrapping{

    @media screen and (max-width: 1000px){
        margin: 0 2%;        
    }

    @media screen and (min-width: 1001px) and (max-width: 1600px){
        margin: 0 5%;        
    }

    margin: 0 10%;        
}