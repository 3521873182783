h6{
    color: #6c757d;
    text-align: center;
    padding-bottom: 20px;
}

.sale-actions{
    display: flex;
    justify-content: space-between;
}

.delete-button{
    color: red;
}

.single-sale{
    margin: 2%;
}

.single-sale-title{
    text-align: center;
    padding: 2% 5%;
}

.sale-info{
    display: flex;
    justify-content: space-evenly;
    background: #dee2e6;
}

.basic-sale-info{
    margin: 5px;
    width: 33%;
    padding: 20px;
    background: #e9ecef;

    b{
        color: #495057;
        font-weight: 400;
    }

    p{
        color:#6c757dd1;
    }
}

.sale-content{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .list-group-item-info {
        color: #212529;
        background-color: #e9ecef;
    }
}
.sale-content-left{
    width: 59%;
}

.sale-content-right{
   width: 39%;
}

.sale-content-list{
    padding: 20px 5px 0;
}

.invoice-list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;   
}

.ad-list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.file-list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e9ecef;
}

.file-buttons{
    margin: 2px;
}

